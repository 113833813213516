import React, { useCallback, useEffect, useState } from 'react'
import { adminLogout } from '../../reducks/admins/operations'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from '../../common'

const Header = () =>
{
  const dispatch = useDispatch()

  const telnumberStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_tel.svg')`
  }

  const mailformStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_mail_bl.svg')`
  }

  return(
    <>
    <footer>
      <div className="contact_infomation_area">
        <div className="telnumber_area">
          <p>お電話によるお問い合わせ</p>
          <p className="telnumber" style={telnumberStyle}>095-882-5888</p>
          <p>10:00～19:00（定休日：第二火曜日）</p>
        </div>
        <div className="logo_image_area">
          <img src={process.env.PUBLIC_URL + '/images/logo_b.svg'}  alt="ACS-エーシーエス-" />
        </div>
        <div className="mailform_area">
          <p>メールによるお問い合わせ</p>
          <div className="mailform" style={mailformStyle}>
            <button className="btn_type_2" onClick={()=>dispatch(push(RouteDir+'/contact'))}>コチラから</button>
          </div>
        </div>
      </div>
      <div className="menu_area">
        <div className="subline_1000">
          <aside className="footer_menu">
            <ul>
              {/* <li onClick={()=>dispatch(push(RouteDir))}>トップページ</li> */}
              <li onClick={()=>dispatch(push(RouteDir+'/howto'))}>ご利用ガイド</li>
              <li onClick={()=>dispatch(push(RouteDir+'/commercial_transaction'))}>特定商取引法に基づく表記</li>
              <li onClick={()=>dispatch(push(RouteDir+'/privacy_policy'))}>プライバシーポリシー</li>
            </ul>
            <div className="media_area">
              <span>公式SNS</span>
              <a href="https://www.instagram.com/acsnagasaki/" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/icon_instagram.svg'} alt="instagram" />
              </a>
              <a href="https://twitter.com/acsnagasaki" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/icon_twitter.svg'} alt="Twitter" />
              </a>
              <a href="https://www.facebook.com/acs.nagasaki" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/icon_facebook.svg'} alt="Facebook" />
              </a>
            </div>
          </aside>
          {/* <div className="contact_infomation_area">
            <div className="telnumber_area">
              <p>お電話によるお問い合わせ</p>
              <p className="telnumber" style={telnumberStyle}>095-000-0000</p>
              <p>9:00～17:00（土・日・祝日は休み）</p>
            </div>
            <div className="logo_image_area">
              <img src={process.env.PUBLIC_URL + '/images/logo_b.svg'}  alt="ACS-エーシーエス-" />
            </div>
            <div className="mailform_area">
              <p>メールによるお問い合わせ</p>
              <div className="mailform" style={mailformStyle}>
                <button className="btn_type_2" onClick={()=>dispatch(push(RouteDir+'/contact'))}>お問い合わせ</button>
              </div>
            </div>
          </div> */}
          {/* <div className="logo_image_area">
            <img src={process.env.PUBLIC_URL + '/images/logo_b.svg'}  alt="ACS-エーシーエス-" />
          </div> */}
        </div>
      </div>
      <div className="copylight_area">
        <p>Copyright © acs All Rights Reserved.</p>
      </div>
    </footer>
    </>
  )
}

export default Header