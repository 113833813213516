import React, { useCallback, useEffect, useState } from "react"
import { insertFloor, insertH1 } from '../../reducks/pageInfos/operations'
import { insertFavorite, deleteFavorite } from '../../reducks/favorites/operations'
import { insertCart } from '../../reducks/carts/operations'
import { Paging } from '../../components/UIkit'
import DocumentMeta from 'react-document-meta'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { SiteTitle } from './common'
import { ApiDir, ImagesDir, RouteDir, ItemImageDir } from '../../common'


const ItemList = (props) =>
{
  //商品IDをURLパラメータから取得
  const pageItemId = props.match.params.id

  const dispatch = useDispatch()

  const [itemInfo, setItemInfo] = useState('')    //商品情報
  const [itemImages, setItemImages] = useState([])    //商品画像情報

  const favoriteList = useSelector(state => state.favorites.list)
  const [favoriteState, setFavoriteState] = useState(false)    //お気に入り登録フラグ

  const [quantity, setQuantity] = useState(1)    //商品個数
  const inputQuantity = useCallback((event) =>
  {
    setQuantity(event.target.value)
  }, [setQuantity])

  useEffect(()=>{
    //パンくずリストをセット
    const Floors =
    [
      {
        name: itemInfo.name,
        href: '/item/edit'+itemInfo.id
      }
    ]
    dispatch(insertFloor(Floors))

    //h1をセット
    dispatch(insertH1(itemInfo.name))

    //商品情報を取得
    let params = new URLSearchParams();
    params.append('item_id',pageItemId);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicItem.php',params)
    .then(function(response){
      console.log(response.data)
      setItemInfo(response.data[0])
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //商品画像情報を取得
    params = new URLSearchParams();
    params.append('item_id',pageItemId);
    params.append('kinds',2);
    params.append('formkey','selectkey');
    axios.post(ApiDir+'/selectPublicItemImage.php',params)
    .then(function(response){
      console.log(response.data)
      setItemImages(response.data)
    })
    .catch(function(error){
      console.log(error)
      return
    })

    //お気に入り一覧の中にあるか調べる
    const favoriteLength = favoriteList.length
    let favoriteFlag = false
    for(let i=0; i<favoriteLength; i++)
    {
      if(favoriteList[i]['item_id'] === pageItemId)
      {
        favoriteFlag = true
      }
    }
    if(favoriteFlag)
    {
      setFavoriteState(true)
    }
    else
    {
      setFavoriteState(false)
    }

  },[dispatch, itemInfo.id, itemInfo.name, pageItemId, favoriteList])

  const insertCartStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_cart.png')`
  }

  const insertFavoriteStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_favorite_bl.svg')`
  }

  const meta =
  {
    title: SiteTitle,
  }

  //お気に入り追加ボタン押下時
  const insertFavoriteBtn = (itemId)=>
  {
    dispatch(insertFavorite(itemId))
  }

  //お気に入り削除ボタン押下時
  const deleteFavoriteBtn = (itemId)=>
  {
    dispatch(deleteFavorite(itemId))
  }

  //買い物カゴに入れるボタン押下時
  const insertCartBtn = (itemId)=>
  {
    dispatch(insertCart(itemId, quantity))
  }

  return(
    <DocumentMeta {...meta}>
      <div id="item_detail_page" className="flex_1">
        <main className="item_detail_content">
          <div className="main_image_area" style={itemInfo.path!==null?{backgroundImage:`url('${ItemImageDir}${itemInfo.path}')`}:{backgroundImage:`url('${process.env.PUBLIC_URL}/images/noimage.jpg')`}}></div>
          <section className="item_info_area">
            <div className="left_content">
              <p className="item_serial">商品番号：{itemInfo.item_serial}</p>
              <p className="standard">規格：{itemInfo.standard}</p>
              <p className="name">
                {itemInfo.name}
                {itemInfo.stock_quantity==='0'&&<span className="sold_out">売り切れ中</span>}
              </p>
              <p className="price">￥{itemInfo && Number(Math.floor(Number(itemInfo.price)*(1+(Number(itemInfo.tax_value)/100)))).toLocaleString()}（税込）</p>
              <p className="postage"><span>送料：￥{itemInfo && Number(itemInfo.postage).toLocaleString()}(税込)</span></p>
              <div className="item_description">
                <p>【商品説明】</p>
                <p>{itemInfo.description}</p>
              </div>
            </div>
            <div className="right_content">
              <div className="wrap">
                <table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>価格</th>
                      <td className="price">￥{itemInfo && Number(Math.floor(Number(itemInfo.price)*(1+(Number(itemInfo.tax_value)/100)))).toLocaleString()}（税込）</td>
                    </tr>
                    <tr>
                      <th>送料</th>
                      <td>￥{itemInfo && Number(itemInfo.postage).toLocaleString()}（税込）</td>
                    </tr>
                  </tbody>
                </table>
                <div className="number_select">
                  数量
                  <input
                    type = "number"
                    name = "quantity"
                    value = {quantity}
                    onChange = {inputQuantity}
                  />
                </div>
                <div className="btn_area">
                  {itemInfo.stock_quantity&&
                    (itemInfo.stock_quantity==='0'?
                      <button className="soldout_btn">売り切れ中</button>:
                      <button className="insert_cart_btn" style={insertCartStyle} onClick={()=>insertCartBtn(itemInfo.id)}>買い物かごに入れる</button>
                    )
                  }
                  {favoriteState===true?
                    <button className="insert_favorite_btn" style={insertFavoriteStyle} onClick={()=>deleteFavoriteBtn(itemInfo.id)}>お気に入りから削除</button>:
                    <button className="insert_favorite_btn" style={insertFavoriteStyle} onClick={()=>insertFavoriteBtn(itemInfo.id)}>お気に入りに追加</button>
                  }
                </div>
              </div>
            </div>
          </section>
          <section className="image_area">
            {Array.isArray(itemImages) && itemImages.map((image, i) =>(
              <div key={i} className="item_image_area" style={image.path!==null?{backgroundImage:`url('${ItemImageDir}${image.path}')`}:{backgroundImage:`url('${process.env.PUBLIC_URL}/images/noimage.jpg')`}}></div>
            ))}
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default ItemList