//開発環境
// export const HomeUrl = 'http://localhost:3000/acs_test/react/'
// export const HomeDir = '/acs_test'
// export const ApiDir = '/acs_test/api'
// export const JsDir = '/acs_test/js'
// export const ImagesDir = '/acs_test/images'
// export const RouteDir = '/acs_test/react'
// export const PublicImageDir = '/acs_test/react/images'
// export const AdminsDir = '/acs_test/react/admin'
// export const MypageDir = '/acs_test/react/mypage'
// export const NewsImageDir = '/acs_test/images/news'
// export const ItemImageDir = '/acs_test/images/items'

//本番環境
export const HomeUrl = 'https://shop-acs.com/'
export const RouteDir = ''
export const HomeDir = ''
export const ApiDir = '/api'
export const JsDir = '/js'
export const SvgDir = '/svg'
export const FontsDir = '/fonts'
export const ImagesDir = '/images'
export const PublicImageDir = '/images'
export const AdminsDir = '/admin'
export const MypageDir = '/mypage'
export const NewsImageDir = '/images/news'
export const ItemImageDir = '/images/items'


//ステージング環境
// export const HomeUrl = 'http://153.127.27.209/seihi_ec_test/'
// export const RouteDir = '/seihi_ec_test'
// export const HomeDir = '/seihi_ec_test'
// export const ApiDir = '/seihi_ec_test/api'
// export const JsDir = '/seihi_ec_test/js'
// export const SvgDir = '/seihi_ec_test/svg'
// export const FontsDir = '/seihi_ec_test/fonts'
// export const ImagesDir = '/seihi_ec_test/images'
// export const PublicImageDir = '/seihi_ec_test/images'
// export const AdminsDir = '/seihi_ec_test/admin'
// export const MypageDir = '/seihi_ec_test/mypage'
// export const NewsImageDir = '/seihi_ec_test/images/news'
// export const ItemImageDir = '/seihi_ec_test/images/items'


//オンライン決済
//テスト決済環境
export const applicationId = 'sandbox-sq0idb-HYRCtQzPcVMk_P4urvjA2A'
export const locationId = 'LJNQ5WBEE7C0Y'
export const copyRight = '*******'


//エラー回避用古い情報
export const postUrl = 'https://sandbox.paygent.co.jp/v/u/request'
export const merchantId = '49225'
export const merchantName = 'ACS-エーシーエス-'