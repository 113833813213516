import React, { useCallback, useEffect, useState } from 'react'
import { changeCategory, changeKeyword } from '../../reducks/items/operations'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from "../../common"


const Sidebar = () =>
{
  const dispatch = useDispatch();

  const keyword = useSelector(state => state.items.selectKeyword)
  //検索商品名の入力
  const [searchNameValue, setSearchName] = useState(keyword)
  const inputSearchName = useCallback((event) =>
  {
    setSearchName(event.target.value)
  }, [setSearchName])

  useEffect(()=>{
    setSearchName(keyword)
  },[keyword])

  const menuListFruitStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/arr_bl.svg')`
  }

  const menuListMeatStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/arr_gy.svg')`
  }

  const menuListVegetableStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_vegetable.png')`
  }

  const menuListProcessedGoodsStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_Processed_goods.png')`
  }

  const menuListFlowerArrangementStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_flower_arrangement.png')`,
    backgroundSize: '25px',
    backgroundPosition: 'right 37px center'
  }

  const menuListOtherStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_other.png')`
  }

  const searchInputStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_search.svg')`
  }

  const selectItemListByCategory = (id)=>
  {
    setSearchName('')
    // dispatch(changeCategory(id))
    dispatch(push(RouteDir + '/item/list?category=' + id))
  }

  const selectItemListByKeyword = (searchNameValue)=>
  {
    // dispatch(changeKeyword(searchNameValue))
    dispatch(push(RouteDir + '/item/list?keyword=' + searchNameValue))
  }


  return(
    <aside className={'sidebar'}>
      <div className="search_area">
        <input
          type = "text"
          name = "search_name"
          style = {searchInputStyle}
          value = {searchNameValue}
          onChange = {inputSearchName}
          placeholder = {'キーワード検索'}
        />
        <button onClick={()=>selectItemListByKeyword(searchNameValue)}>検索</button>
      </div>
      <div className="menu_list">
        <h2>カテゴリ<span>category</span></h2>
        <ul>
          <li style={menuListFruitStyle} onClick={()=>selectItemListByCategory(1)}>
            <span>タイヤ</span>
          </li>
          <li style={menuListFruitStyle} onClick={()=>selectItemListByCategory(2)}>
            <span>カーコーティング剤</span>
          </li>
          <li style={menuListFruitStyle} onClick={()=>selectItemListByCategory(3)}>
            <span>カー用品</span>
          </li>
          {/* <li style={menuListProcessedGoodsStyle} onClick={()=>selectItemListByCategory(4)}>
            <span>加工品</span>
          </li>
          <li style={menuListFlowerArrangementStyle} onClick={()=>selectItemListByCategory(5)}>
            <span>生花</span>
          </li>
          <li style={menuListOtherStyle} onClick={()=>selectItemListByCategory(6)}>
            <span>その他</span>
          </li> */}
        </ul>
      </div>
      <div className='contents_list'>
        <h2>コンテンツ<span>contents</span></h2>
        <ul>
          <li style={menuListMeatStyle} onClick={()=>dispatch(push(RouteDir+'/mypage/favorite_list'))}>お気に入り商品</li>
          <li style={menuListMeatStyle} onClick={()=>dispatch(push(RouteDir+'/mypage/cart_list'))}>買い物カゴ</li>
          <li style={menuListMeatStyle} onClick={()=>{dispatch(push(RouteDir+'/contact'))}}>お問い合わせ</li>
          <li style={menuListMeatStyle} onClick={()=>dispatch(push(RouteDir+'/signup_application'))}>新規会員登録</li>
          <li style={menuListMeatStyle} onClick={()=>dispatch(push(RouteDir+'/login'))}>ログイン</li>
        </ul>
      </div>
      <div className='site_btn'>
        <a href="https://acs-n.jp/" target='_blank'>
          <img src={process.env.PUBLIC_URL + '/images/bunner_official.svg'} alt="ACS公式サイト" />
        </a>
      </div>
      <div className='sns_list'>
        <h2>
          <img src={process.env.PUBLIC_URL + '/images/logo_b.svg'} alt="ACSロゴ" />
          公式アカウント
        </h2>
        <ul>
          <li>
            <a href="https://www.instagram.com/acsnagasaki/" target="_blank" rel="noreferrer">
              <img src={process.env.PUBLIC_URL + '/images/icon_instagram.svg'} alt="instagram" />
              <span>@acsnagasaki</span>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/acsnagasaki" target="_blank" rel="noreferrer">
              <img src={process.env.PUBLIC_URL + '/images/icon_twitter.svg'} alt="twitter" />
              <span>@acsnagasaki</span>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/acs.nagasaki" target="_blank" rel="noreferrer">
              <img src={process.env.PUBLIC_URL + '/images/icon_facebook.svg'} alt="Facebook" />
              <span>@acsnagasaki</span>
            </a>
          </li>
        </ul>
        <p className='red'>Follow Me!!</p>
      </div>
    </aside>
  )
}

export default Sidebar